import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'

const Stores = ({stores, categories, height}) => {
  const mouseOver = (name) => {
    document.querySelector('.stores').classList.add('hover');
    document.querySelector('.store--link[data-link="'+name+'"]').classList.add('active')
  }
  const mouseOut = () => {
    document.querySelector('.stores').classList.remove('hover');
    document.querySelector('.store--link.active')?.classList.remove('active');
  }
  const categoryHover = (e) => {
    document.querySelector('.category.active')?.classList.remove('active');
    var category = e.target;
    var cat = e.target.getAttribute('data-category');
    category.classList.add('active');
    if(cat !=='All') {
      document.querySelector('.stores').classList.add('hover');
      var stores = document.querySelectorAll('.store--link[data-category="'+cat+'"]');
      for (var i = 0; i < stores.length; ++i) {
         stores[i].classList.add('active');
      }
    }
  }
  const categoryMouseout = () => {
    document.querySelector('.stores').classList.remove('hover');
    document.querySelector('.category.active').classList.remove('active')
    var active = document.querySelectorAll('.store--link.active');
    for (var i = 0; i < active.length; ++i) {
      active[i].classList.remove('active');
    }
    document.querySelector('.category[data-category="All"]').classList.add('active')
  }
  return (
  <div className={'stores mh-100a flex '+(height ? 'mh-100ap':'mh-100a')}>
    <div className='ma max-1250 text-center p20 m-pt10'>
      <div className='store--categories text-center small grey mb10 uppercase m-hide'>
        <span className='mr20 category active' onMouseOut={categoryMouseout} onMouseOver={categoryHover} onBlur={categoryMouseout} onFocus={categoryHover} role='presentation' data-category='All'>(All)</span>
        {categories.nodes.map((category, index) => {
          return ( 
            <span key={index} data-category={category.category} onMouseOut={categoryMouseout} onBlur={categoryMouseout} onMouseOver={categoryHover} onFocus={categoryHover} role='presentation' className='category mr20'>({category.category})</span>
          )
        })}
      </div>
      <div className='stores m-center'>
      {stores.map((store, index) => {
        store = store.node;
        return (
          <Link className='m0 m-block link inline mr20 m-m0 pos-rel store--link m-mb10' data-link={store.name} data-category={store.category.category} key={index} onMouseOver={() => mouseOver(store.name)} onMouseOut={mouseOut} to={'/store/'+store.slug}>
            <span className='flex m-block'>
              <span className='h1 m-100 m-block m-mbn10'>{store.name}</span>
              <span className='xs ml5 mt5'>{store.category.category}</span>
            </span>
            <div className='store--thumbnail'>
              <div className='ratio-1-2 pos-rel'>
                <GatsbyImage className='bg-image' image={store.gallery[0]?.gatsbyImageData} alt='Store Thumbnail' />
              </div>
            </div>
          </Link>
        )
      })}
      </div>
    </div>
  </div>
  )
}

export default Stores
