import * as React from "react"
import Layout from "../components/layout"
import Stores from "../components/stores"
import Seo from "../components/seo"
import { graphql } from 'gatsby'

const IndexPage = ({data}) => {
  return (
  <Layout preloader={true}>
    <Stores categories={data.categories} stores={data.stores.edges} />
  </Layout>
  )
} 


export const Head = () => <Seo title="The new Byron laneway precinct" />

export default IndexPage


export const query = graphql`
query HomeQuery {
  stores:allDatoCmsStore(sort: {fields: position}) {
    edges {
      node {
        name
        slug
        position
        category {
          category
        }
        gallery {
          gatsbyImageData
        }
      }
    }
  }
  categories:allDatoCmsCategory {
    nodes {
      category
    }
  }
}
`